.pac-container {
  z-index: 10000 !important;
}

.form-horizontal .control-label.text-left{
  text-align: left;
}

.tab-content .form-horizontal {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.form-header {
  font-size: 23px;
  border-bottom: 1px solid #eee;
  margin-bottom: 30px;
  padding-bottom: 10px;
}

.show-modal {
  visibility: visible;
}

.hide-modal {
  z-index: 99;
}

.page-heading {
  margin: 0 0 15px;
  letter-spacing: 0.4px;
  border-bottom: 1px $table-border-color solid;
  padding-bottom: 17px;
}

.drag-icon {
  cursor: n-resize;
  color: $input-color-placeholder;
  margin-right: 4px;
}

.error-input {
  border-color: $brand-danger;
}
.error-msg {
  color: $brand-danger;
  font-size: 14px;
  line-height: 20px;
  margin: 4px 0;
}
