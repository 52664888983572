.btn {
    border-radius: 4px;
}
.btn-invert {
  @include transition(.25s);
  background: transparent;
  border: 1px $gray-light solid;
  color: $gray;
  &:not(:disabled) {
    &:hover,
    &:focus,
    &:active {
      background: $gray-lighter;
    }
  }
  &.btn {
    border-color: transparent;
    color: $brand-primary;
  }
  &.btn-primary {
    border: 1px $brand-primary solid;
    color: $brand-primary;
    &:focus {
      &:active {
        color: $brand-primary;
      }
    }
  }
  &.btn-danger {
    color: $brand-danger;
    border-color: transparent;
    &:focus {
      &:active {
        color: $brand-danger;
      }
    }
  }
  &.btn-default {
    color: $input-color-placeholder;
    border-color: $input-color-placeholder;
    &:focus {
      &:active {
        color: $input-color-placeholder;
      }
    }

  }
}

.button-bar {
  border-top: 1px $table-border-color solid;
  margin: 20px 0;
  padding-top: 38px;
  .btn {
    &:not(:first-of-type) {
      margin-left: 19px;
    }
  }
}
